import React, { useCallback, useState, useEffect } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import { styled } from '@glitz/react';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import { theme } from '../Theme';
import { media } from '@glitz/core';
import TepeShareCard from './../Shared/TepeShareCard/TepeShareCard';
import Pagination from '../Shared/Pagination/Pagination';
import { FetchPagination } from '../Shared/Pagination/PaginationFetcher';
import { ChipsButton } from '../Shared/Button/Button';
import TepeShareListingPageModel from './Models/TepeShareListingPageModel.interface';
import TepeSharePaginationItemModel from './Models/TepeSharePaginationItemModel.interface';
import TagModel from './Models/TagModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

const PAGE_SIZE_FILTER = 12;

function TepeShareListingPage() {
  const {
    heading,
    subHeading,
    introText,
    tags,
    pageId,
    paginationType,
    inEditMode,
  } = useCurrentPage<TepeShareListingPageModel>();

  const {
    translations: { 'tepeShareDetailPage/noResult': noResultText },
    languageRoute,
  } = useAppSettingsData();

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [tepeShareContent, setTepeShareContent] = useState<
    TepeSharePaginationItemModel[]
  >([]);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [activeTagIds, setActiveTagIds] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const onFetchError = useCallback((message: string) => {
    setErrorText(message);
    setTimeout(() => {
      setErrorText(null);
    }, 5000);
  }, []);

  const onFetchResult = useCallback((data: any) => {
    setTepeShareContent(data.paginationItems);
    setTotalRecords(data.totalRecords);
  }, []);

  const buildFilter = useCallback(
    () => ({
      pageId: pageId.toString(),
      ...(activeTagIds && {
        tags: activeTagIds,
      }),
    }),
    [activeTagIds, pageId]
  );

  const fetchPagination = useCallback(
    (page?: number) => {
      FetchPagination(
        paginationType,
        page || 0,
        PAGE_SIZE_FILTER,
        buildFilter(),
        onFetchResult,
        onFetchError,
        languageRoute
      );
      setCurrentPage(page || 0);
    },
    [paginationType, onFetchResult, onFetchError, buildFilter, languageRoute]
  );

  const onPageChange = useCallback(
    (page: number) => {
      fetchPagination(page);
    },
    [fetchPagination]
  );

  useEffect(() => {
    fetchPagination();
  }, [fetchPagination]);

  useEffect(() => {
    setActiveTagIds([]);
  }, [pageId]);

  const setFilterTag = useCallback(
    id => {
      if (activeTagIds.includes(id)) {
        let activeTags = activeTagIds.filter(tag => tag !== id);
        setActiveTagIds([...activeTags]);
        return;
      }
      setActiveTagIds([...activeTagIds, id]);
      setCurrentPage(0);
    },
    [activeTagIds]
  );

  return (
    <>
      <Container>
        <Content>
          {heading && (
            <Title {...applyEditModeAttr(inEditMode && 'Title')}>
              {heading}
            </Title>
          )}
          {subHeading && (
            <SubTitle {...applyEditModeAttr(inEditMode && 'SubTitle')}>
              {subHeading}
            </SubTitle>
          )}
          {introText && (
            <XhtmlComponent
              {...applyEditModeAttr(inEditMode && 'IntroText')}
              content={introText}
            />
          )}
        </Content>
        <FilterTagsWrapper>
          {tags &&
            tags.map((item: TagModel, _i: number) => {
              return (
                <StyledChipsButton
                  key={_i}
                  onClick={() => setFilterTag(item.tagName)}
                  isActive={activeTagIds.includes(item.tagName)}
                >
                  {item.tagName} - {item.tagHitCount}
                </StyledChipsButton>
              );
            })}
        </FilterTagsWrapper>

        <ResultWrapper>
          {tepeShareContent &&
            tepeShareContent.map(
              (item: TepeSharePaginationItemModel, _i: number) => {
                return <TepeShareCard item={item} key={_i} />;
              }
            )}
        </ResultWrapper>

        {!tepeShareContent.length && <NoResult>{noResultText}</NoResult>}

        {errorText && <ErrorMessage>{errorText}</ErrorMessage>}

        {Boolean(totalRecords) && (
          <Pagination
            totalRecords={totalRecords}
            numberOfRecords={PAGE_SIZE_FILTER}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        )}
      </Container>
    </>
  );
}

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto', bottom: theme.spacing(16) },
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.none,
    },
  }),
});

const Content = styled.div({
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinMedium, {
    marginBottom: theme.spacing(16),
  }),
});

const Title = styled.h1({
  font: {
    family: theme.fontFamily.heading,
    size: theme.iota,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const SubTitle = styled.p({
  font: {
    weight: theme.fontWeight.bold,
    size: theme.delta,
  },
  lineHeight: theme.lineHeight.normal,
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const ErrorMessage = styled.p({
  color: theme.errorText,
  textAlign: 'center',
  marginBottom: theme.spacing(8),
});

const FilterTagsWrapper = styled.div({
  display: 'block',
  textAlign: 'center',
  marginBottom: theme.spacing(8),
});

const StyledChipsButton = styled(ChipsButton, {
  margin: { xy: theme.spacing(2) },
});

const ResultWrapper = styled.div({
  display: 'grid',
  gridGap: theme.spacing(8),
  gridTemplateColumns: '1fr',
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: '1fr 1fr',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '1fr 1fr 1fr',
  }),
  margin: { y: theme.spacing(8) },
});

const NoResult = styled.p({
  textAlign: 'center',
  margin: { x: 'auto' },
});

export default TepeShareListingPage;
