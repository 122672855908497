import React from 'react';
import { styled, theme } from '../../Theme';
import ImageRatio from './../Image/ImageRatio';
import ImageBadge from './../ImageBadge/ImageBadge';
import KexLink from './../KexLink/KexLink';
import TepeSharePaginationItemModel from './../../TepeShareListingPage/Models/TepeSharePaginationItemModel.interface';

type PropType = {
  item: TepeSharePaginationItemModel;
};

function TepeShareCard({ item }: PropType) {
  const truncateText = (text: string) => {
    return `${text.substring(0, 122)}...`;
  };

  return (
    <styled.Div>
      {item.image?.src && (
        <KexLink href={item.pageUrl}>
          <ImageWrapper>
            <ImageRatio src={item.image.src} />
            <ImageBadge title={item.badgeName} />
          </ImageWrapper>
        </KexLink>
      )}
      <Content>
        {item.subHeader && <SubHeader>{item.subHeader}</SubHeader>}
        <KexLink href={item.pageUrl}>
          {item.header && <Header>{item.header}</Header>}
        </KexLink>
        {item.introText && <BodyText>{truncateText(item.introText)}</BodyText>}
      </Content>
    </styled.Div>
  );
}

const ImageWrapper = styled.div({
  position: 'relative',
});

const Content = styled.div({
  marginTop: theme.spacing(4),
});

const SubHeader = styled.p({
  font: {
    size: theme.alpha,
  },
  color: theme.black,
  marginBottom: theme.spacing(1),
});

const Header = styled.h4({
  font: {
    size: theme.epsilon,
    family: theme.fontFamily.heading,
    weight: theme.fontWeight.bold,
  },
  color: theme.blueDark,
  marginBottom: theme.spacing(2),
});

const BodyText = styled.p({
  font: {
    size: theme.beta,
  },
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
});

export default TepeShareCard;
