import React from 'react';
import { styled, theme } from '../../Theme';
import { StyledProps } from '@glitz/react';
import { media } from '@glitz/core';

type PropType = StyledProps & {
  title: string;
};

function ImageBadge({ title, compose }: PropType) {
  return (
    <Badge css={compose()}>
      <Title>{title}</Title>
    </Badge>
  );
}

export default styled(ImageBadge);

const Badge = styled.div({
  left: 0,
  bottom: 0,
  width: 'auto',
  backgroundColor: theme.mediumAqua,
  textAlign: 'center',
  position: 'absolute',
  padding: { xy: theme.spacing(2) },
  ...media(theme.mediaQuery.mediaMinMedium, {
    padding: { x: theme.spacing(4), y: theme.spacing(2) },
  }),
});

const Title = styled.p({
  font: {
    size: theme.alpha,
    weight: theme.fontWeight.normal,
  },
  color: theme.blueDark,
});
